import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import { Amplify } from 'aws-amplify'
import aws_exports from './aws-exports';

import App from './App.vue'

import CouponsPage from './components/CouponsPage.vue'
import AffiliatesPage from './components/AffiliatesPage.vue'
import HomePage from './components/HomePage.vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
// index.js
import '@material/web/button/filled-button.js';
import '@material/web/button/outlined-button.js';
import '@material/web/checkbox/checkbox.js';

Amplify.configure(aws_exports);

const vuetify = createVuetify({
    components: { ...components },
    directives: directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        }
    }
})

const routes = [
    { path: '/', component: HomePage },
    { path: '/coupons', component: CouponsPage },
    { path: '/affiliates', component: AffiliatesPage }
]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

createApp(App).use(router).use(vuetify).mount('#app')
