<template>
  <v-container>
    <v-row align="start">
      <v-btn color="primary" @click="dialog = true; showDialog()" style="margin-right:5px">
        Create Coupon
        <v-icon end icon="mdi-plus"></v-icon>
      </v-btn>
      <v-btn color="primary" @click="importDialog = true; showDialog()" style="margin-right:5px">
        Import
        <v-icon end icon="mdi-import"></v-icon>
      </v-btn>
      <v-btn color="primary" @click="exportDialog = true; showDialog()">
        Export
        <v-icon end icon="mdi-export"></v-icon>
      </v-btn>
    </v-row>
  </v-container>
  <v-dialog v-model="dialog" persistent width="1024">
    <v-card>
      <v-card-title>
        <span class="text-h5">Create Coupon</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete v-model="createForm.affiliate" v-model:search="affiliateSearch" :loading="loading" :items="affiliates" density="comfortable" hide-no-data hide-details label="Affiliate"></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Coupon*" v-model="createForm.coupon" required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Start date" v-model="createForm.startDate" type="date"></v-text-field>
              <v-text-field label="Expire date" v-model="createForm.expireDate" type="date"></v-text-field>

              <span class="text-h5">Extra</span>
            </v-col>

            <v-col cols="12">
              <v-text-field label="Amount (TL)" v-model="createForm.data.amount" required></v-text-field>
              <v-text-field label="Commission (TL)" v-model="createForm.data.commission" required></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="primary" variant="elevated" @click="createCouponSubmit()">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="importDialog" persistent width="1024">
    <v-card>
      <v-card-title>
        <span class="text-h5">Import coupons</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-file-input accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" label="File input*" @change="onFileSelected"></v-file-input>
            </v-col>

            <v-col cols="12" v-if="importForm.worksheet">
              <v-alert v-if="importForm.errorMessage" :text="importForm.errorMessage" type="error"></v-alert>

              <span class="text-h5">Fields</span>
              <v-autocomplete v-model="importForm.coupon" :items="importForm.fields" label="Coupon code*"></v-autocomplete>
              <v-autocomplete v-model="importForm.affiliate" :items="importForm.fields" label="Affiliate*"></v-autocomplete>

              <span class="text-h5">General</span>
              <v-text-field label="Start date" v-model="importForm.startDate" type="date"></v-text-field>
              <v-text-field label="Expire date" v-model="importForm.expireDate" type="date"></v-text-field>

              <span class="text-h5">Extra</span>
              <v-autocomplete v-model="importForm.data.amount" :items="importForm.fields" label="Amount (TL)"></v-autocomplete>
              <v-autocomplete v-model="importForm.data.commission" :items="importForm.fields" label="Commission (TL)"></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="importDialog = false">
          Close
        </v-btn>
        <v-btn color="primary" variant="elevated" @click="importSubmit()">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="exportDialog" persistent width="1024">
    <v-card>
      <v-card-title>
        <span class="text-h5">Export coupons</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-alert v-if="exportForm.errorMessage" :text="exportForm.errorMessage" type="error"></v-alert>

              <span class="text-h5">Filter</span>
              <v-autocomplete v-model="generalForm.affiliate" v-model:search="generalForm.affiliateSearch" :loading="generalForm.affiliateLoading" :items="generalForm.affiliates" label="Affiliate"></v-autocomplete>

            </v-col>

            <v-col cols="6" sm="6" md="6">
              <!-- <v-date-picker range="start" v-model="importForm.startDate"></v-date-picker> -->
              <v-text-field class="mx-2" rows="1" label="Start date from" v-model="exportForm.startsFrom" type="date"></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-text-field class="mx-2" rows="1" label="Start date to" v-model="exportForm.startsTo" type="date"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field class="mx-2" rows="1" label="Sold at from" v-model="exportForm.soldFrom" type="date"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field class="mx-2" rows="1" label="Sold at to" v-model="exportForm.soldTo" type="date"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field class="mx-2" rows="1" label="Claimed at from" v-model="exportForm.claimedFrom" type="date"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field class="mx-2" rows="1" label="Claimed at to" v-model="exportForm.claimedTo" type="date"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="exportDialog = false">
          Close
        </v-btn>
        <v-btn color="primary" variant="elevated" @click="exportSubmit()">
          Export
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancel</v-btn>
        <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-container style="text-align: left;">
    <span class="text-h5">Filter</span><br>
    <v-row align="start" style="width: 50%;margin-top: 5px;">

      <v-autocomplete v-model="generalForm.affiliate" v-model:search="generalForm.affiliateSearch" :loading="generalForm.affiliateLoading" :items="generalForm.affiliates" label="Affiliate"></v-autocomplete>
      <v-text-field v-model="name" hide-details placeholder="Search coupon..." style="margin-left: 15px;min-width: 350px;"></v-text-field>
    </v-row>
  </v-container>
  <v-data-table-server v-model:items-per-page="itemsPerPage" :search="search" :headers="headers" :items-length="totalItems" :items="serverItems" :loading="loading" class="elevation-1" item-value="name" @update:options="loadItems">

    <template v-slot:[`item.actions`]="{ item }">
      <!-- <v-icon size="small" class="me-2" @click="console.log">
        mdi-pencil
      </v-icon> -->
      <v-icon size="small" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>


  </v-data-table-server>
</template>
  
<script>
import { API, graphqlOperation } from 'aws-amplify';
import { listCoupons, listAffiliates } from '../graphql/queries';
import { createCoupon, deleteCoupon } from '../graphql/mutations';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';


const FakeAPI = {
  async fetch({ sortBy, search, affiliateSearch }) {
    return new Promise(resolve => {

      let options = {
        sortDirection: sortBy
      };
      if (search.name) {
        options.filter = {
          code: { contains: search.name }
        }
      }
      if (affiliateSearch) {
        options.filter = {
          ...options.filter,
          affiliateCODE: { eq: affiliateSearch }
        }
      }

      API.graphql(graphqlOperation(listCoupons, options)).then((result) => {
        resolve({
          items: result.data.listCoupons.items.map((coupon) => {
            return {
              id: coupon.id,
              code: coupon.code,
              affiliateCODE: coupon.affiliateCODE,
              data: JSON.parse(coupon.data),
              startsAt: coupon.startsAt,
              soldAt: coupon.soldAt,
              expiresAt: coupon.expiresAt,
              claimedAt: coupon.claimedAt
            };
          }), total: result.data.listCoupons.items.length
        })
      });

    })
  },
}

export default {
  data() {
    return {
      dialog: false,
      importDialog: false,
      exportDialog: false,
      dialogDelete: false,
      generalForm: {
        affiliates: [],
        affiliateSearch: '',
        affiliate: ''
      },
      createForm: {
        affiliate: '',
        affiliateLoading: false,
        coupon: '',
        startDate: null,
        expireDate: null,
        soldAt: null,
        data: {
          amount: 0,
          commission: 0
        }
      },
      importForm: {
        fields: [],
        affiliate: '',
        coupon: '',
        data: {
          amount: '',
          commission: ''
        },
        startDate: null,
        expireDate: null,
        worksheet: null,
        errorMessage: null
      },
      exportForm: {
        fields: [],
        affiliate: '',
        startsFrom: null,
        startsTo: '',
        claimedFrom: '',
        claimedTo: ''
      },
      itemsPerPage: 15,
      headers: [
        {
          title: 'Coupon',
          align: 'start',
          sortable: false,
          key: 'code',
        },
        { title: 'Affiliate', key: 'affiliateCODE', align: 'end' },
        { title: 'Amount (TL)', key: 'data.amount', align: 'end' },
        { title: 'Commision (TL)', key: 'data.comission', align: 'end' },
        { title: 'Starts At', key: 'startsAt', align: 'end' },
        { title: 'Expires At', key: 'expiresAt', align: 'end' },
        { title: 'Sold At', key: 'soldAt', align: 'end' },
        { title: 'Claimed At', key: 'claimedAt', align: 'end' },
        { title: 'Actions', key: 'actions', sortable: false }
      ],
      serverItems: [],
      loading: true,
      affiliateLoading: false,
      totalItems: 0,
      name: '',
      search: {},

      affiliates: [],
      affiliateSearch: ''
    }
  },
  watch: {
    name() {
      this.search = String(Date.now())
    },
    affiliateSearch(val) {
      val && val !== this.select && this.queryAffiliates(val, this)
    },
    'generalForm.affiliateSearch': function (val) {
      val && val !== this.select && this.queryAffiliates(val, this.generalForm)
      this.search = String(Date.now())
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    showDialog() {
      this.createForm.coupon = this.generateCouponCode();
    },
    loadItems({ page, itemsPerPage, sortBy }) {
      this.loading = true
      FakeAPI.fetch({ page, itemsPerPage, sortBy, search: { name: this.name }, affiliateSearch: this.generalForm.affiliate }).then(({ items, total }) => {
        const start = (page - 1) * itemsPerPage
        const end = start + itemsPerPage

        const paginated = items.slice(start, end)

        this.serverItems = paginated
        this.totalItems = total
        this.loading = false
      })
    },
    generateCouponCode() {
      let couponCode = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      for (let i = 0; i < 18; i++) {
        couponCode += characters.charAt(Math.floor(Math.random() * characters.length));
      }

      return couponCode;
    },
    createCouponSubmit() {
      return API.graphql(graphqlOperation(createCoupon, {
        input: {
          affiliateCODE: this.createForm.affiliate,
          code: this.createForm.coupon,
          data: JSON.stringify(this.createForm.data),
          startsAt: (this.createForm.startDate == null ? new Date() : new Date(this.createForm.startDate)).toISOString(),
          expiresAt: this.createForm.expireDate == null ? null : this.createForm.expireDate.toISOString()
        }
      }))
        .then(() => {
          this.dialog = false;
        })
        .catch(error => {
          console.error('Error creating affiliate:', error);
        });
    },
    createCouponOperation(affiliate, code, startsAt, expiresAt, data) {
      return API.graphql(graphqlOperation(createCoupon, {
        input: {
          affiliateCODE: affiliate,
          code: code,
          data: JSON.stringify(data),
          startsAt: startsAt == '' || startsAt == null ? new Date() : new Date(startsAt).toISOString(),
          expiresAt: expiresAt == '' || expiresAt == null ? null : new Date(expiresAt).toISOString(),
        }
      }));
    },
    importSubmit() {
      this.importForm.errorMessage = null;

      API.graphql(graphqlOperation(listAffiliates)).then((result) => {
        this.affiliates = result.data.listAffiliates.items.map(aff => aff.code);

        const jsonData = XLSX.utils.sheet_to_json(this.importForm.worksheet, { header: 1 });
        const headers = jsonData[0];

        const couponCodeIndex = headers.indexOf(this.importForm.coupon);
        const affiliateIndex = headers.indexOf(this.importForm.affiliate);

        const dataAmountIndex = this.importForm.data.amount ? headers.indexOf(this.importForm.data.amount) : null;
        const dataCommissionIndex = this.importForm.data.amount ? headers.indexOf(this.importForm.data.commission) : null;

        const rows = jsonData.slice(1);
        rows.forEach((row, index) => {
          const affiliate = row[affiliateIndex];
          const couponCode = row[couponCodeIndex];

          if (affiliate === undefined) return;
          if (this.affiliates.indexOf(affiliate) == -1) {
            this.importForm.errorMessage = `Affiliate ${affiliate} doesnt exist`;
            return;
          }
          if (couponCode === undefined) {
            this.importForm.errorMessage = `Coupon at row  ${index} invalid`;
            return;
          }
        });

        if (this.importForm.errorMessage == null) {
          rows.forEach((row) => {
            const affiliate = row[affiliateIndex];
            if (affiliate === undefined) return;

            const couponCode = row[couponCodeIndex];

            let data = {};
            if (dataAmountIndex != null) {
              data['amount'] = row[dataAmountIndex];
            }
            if (dataAmountIndex != null) {
              data['commission'] = row[dataCommissionIndex];
            }

            this.createCouponOperation(affiliate, couponCode, this.importForm.startDate, this.importForm.expireDate, data);
          });

          this.importDialog = false;
        }
      })
    },
    exportSubmit() {
      let options = {
        filter: {}
      };

      if (this.exportForm.startsFrom || this.exportForm.startsTo) {
        options.filter['startsAt'] = {
          between: [
            this.exportForm.startsFrom ? (new Date(this.exportForm.startsFrom)).toISOString() : '1970-01-01T00:00:00.000Z',
            this.exportForm.startsTo ? (new Date(this.exportForm.startsTo)).toISOString() : '2999-01-01T00:00:00Z'
          ]
        }
      }
      if (this.exportForm.soldFrom || this.exportForm.soldTo) {
        options.filter['soldAt'] = {
          between: [
            this.exportForm.soldFrom ? (new Date(this.exportForm.soldFrom)).toISOString() : '1970-01-01T00:00:00.000Z',
            this.exportForm.soldTo ? (new Date(this.exportForm.soldTo)).toISOString() : '2999-01-01T00:00:00Z'
          ]
        }
      }
      if (this.exportForm.claimedFrom || this.exportForm.claimedTo) {
        options.filter['claimedAt'] = {
          between: [
            this.exportForm.claimedFrom ? (new Date(this.exportForm.claimedFrom)).toISOString() : '1970-01-01T00:00:00.000Z',
            this.exportForm.claimedTo ? (new Date(this.exportForm.claimedTo)).toISOString() : '2999-01-01T00:00:00Z'
          ]
        }
      }

      if (this.generalForm.affiliate) {
        options.filter['affiliateCODE'] = { eq: this.generalForm.affiliate }
      }


      API.graphql(graphqlOperation(listCoupons, options)).then((result) => {
        const csv = Papa.unparse(result.data.listCoupons.items.map(row => {
          let couponData = JSON.parse(row.data);
          return {
            affiliate: row.affiliateCODE,
            code: row.code,
            amount: couponData.amount,
            commission: couponData.commission,
            startsAt: row.startsAt,
            expiresAt: row.expiresAt,
            soldAt: row.soldAt,
            claimedAt: row.claimedAt,
            createdAt: row.createdAt
          };
        }));
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'export.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        console.log(result);
        // resolve({ items: result.data.listCoupons.items, total: result.data.listCoupons.items.length })
      });

      console.log(this.generalForm.affiliate)
    },
    queryAffiliates(searchStr, formObject) {
      formObject.affiliateLoading = true;

      let options = {}
      if (searchStr) {
        options.filter = {
          code: { contains: searchStr }
        }
      }
      API.graphql(graphqlOperation(listAffiliates, options)).then((result) => {
        formObject.affiliates = result.data.listAffiliates.items.map(aff => aff.code);
        formObject.affiliateLoading = false
      }).catch(error => {
        console.error('Error retreiving affiliates:', error);
        formObject.affiliateLoading = false
      });
    },
    deleteItem(item) {
      this.editedIndex = this.serverItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.serverItems.splice(this.editedIndex, 1)
      console.log(this.editedItem);
      return API.graphql(graphqlOperation(deleteCoupon, {
        input: {
          id: this.editedItem.id,
        }
      }))
        .then(() => {
          this.closeDelete()
        })
        .catch(error => {
          console.error('Error deleting coupon:', error);
        });
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    onFileSelected(e) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Get the first worksheet
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        this.importForm.worksheet = worksheet;

        // Convert worksheet to JSON
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Get the headers
        const headers = jsonData[0];

        this.importForm.fields = headers;
        console.log(headers);
      };

      reader.readAsArrayBuffer(file);
    }
  },
}
</script>