/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAffiliate = /* GraphQL */ `
  mutation CreateAffiliate(
    $input: CreateAffiliateInput!
    $condition: ModelAffiliateConditionInput
  ) {
    createAffiliate(input: $input, condition: $condition) {
      code
      name
      apiKey
      isActive
      expiresAt
      coupons {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAffiliate = /* GraphQL */ `
  mutation UpdateAffiliate(
    $input: UpdateAffiliateInput!
    $condition: ModelAffiliateConditionInput
  ) {
    updateAffiliate(input: $input, condition: $condition) {
      code
      name
      apiKey
      isActive
      expiresAt
      coupons {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAffiliate = /* GraphQL */ `
  mutation DeleteAffiliate(
    $input: DeleteAffiliateInput!
    $condition: ModelAffiliateConditionInput
  ) {
    deleteAffiliate(input: $input, condition: $condition) {
      code
      name
      apiKey
      isActive
      expiresAt
      coupons {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      id
      affiliateCODE
      code
      data
      state
      startsAt
      expiresAt
      soldAt
      txId
      claimedAt
      affiliate {
        code
        name
        apiKey
        isActive
        expiresAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      affiliateCouponsCode
      owner
      __typename
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      id
      affiliateCODE
      code
      data
      state
      startsAt
      expiresAt
      soldAt
      txId
      claimedAt
      affiliate {
        code
        name
        apiKey
        isActive
        expiresAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      affiliateCouponsCode
      owner
      __typename
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon(
    $input: UpdateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    updateCoupon(input: $input, condition: $condition) {
      id
      affiliateCODE
      code
      data
      state
      startsAt
      expiresAt
      soldAt
      txId
      claimedAt
      affiliate {
        code
        name
        apiKey
        isActive
        expiresAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      affiliateCouponsCode
      owner
      __typename
    }
  }
`;
