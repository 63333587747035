<template>
  <v-layout>
    <v-btn color="primary" @click="dialog = true; showDialog()">
      Create Affiliate
      <v-icon end icon="mdi-plus"></v-icon>
    </v-btn> </v-layout>
  <v-dialog v-model="dialog" persistent width="1024">
    <v-card>
      <v-card-title>
        <span class="text-h5">Create affiliate</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field label="Affiliate*" v-model="createForm.affiliate" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field label="Name*" v-model="createForm.name" required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="API Key*" v-model="createForm.apiKey" required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Expire date" v-model="createForm.expireDate" type="date"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
          Close
        </v-btn>
        <v-btn color="primary" variant="elevated" @click="createAffiliateSubmit()">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogDelete" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancel</v-btn>
        <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-dialog v-model="apiDialog" width="1024">
    <v-card>
      <v-card-title>
        <span class="text-h5">API Dialog</span>
        <v-card-text v-if="affiliateSelected">
          <v-container>
            <v-row>
              <small>/affiliates/{affiliate}/coupons</small>
            </v-row>
            <v-row >
              <p class="font-weight-light" style="text-wrap: balance">curl https://pin.sisal-sans.com/affiliates/{{ affiliateSelected.code }}/coupons?x-api-key={{ affiliateSelected.apiKey }}</p>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <small>/affiliates/{affiliate}/coupon/buy</small>
            </v-row>
            <v-row >
              <p class="font-weight-light" style="text-wrap: balance">curl https://pin.sisal-sans.com/affiliates/{{ affiliateSelected.code }}/coupon/buy?x-api-key={{ affiliateSelected.apiKey }}</p>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <small>/affiliates/{affiliate}/coupon/use</small>
            </v-row>
            <v-row >
              <p class="font-weight-light" style="text-wrap: balance">curl https://pin.sisal-sans.com/affiliates/{{ affiliateSelected.code }}/coupon/claim?x-api-key={{ affiliateSelected.apiKey }}&coupon={coupon}</p>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <small>/affiliates/{affiliate}/coupon/use (Vars)</small>
            </v-row>
            <v-row >
              <p class="font-weight-light" style="text-wrap: balance">curl https://pin.sisal-sans.com/affiliates/{{ affiliateSelected.code }}/coupon/claim?x-api-key={{ affiliateSelected.apiKey }}&coupon={coupon}&state[gclid]={GCLID}&state[userid]={userId}</p>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="apiDialog = false">
          Close
        </v-btn>
        </v-card-actions>
      </v-card-title>
    </v-card>
  </v-dialog>

  <v-data-table-server v-model:items-per-page="itemsPerPage" :search="search" :headers="headers" :items-length="totalItems" :items="serverItems" :loading="loading" class="elevation-1" item-value="name" @update:options="loadItems">
    <template v-slot:tfoot>
      <tr>
        <td>
          <v-text-field v-model="name" hide-details placeholder="Search name..." class="ma-2" density="compact"></v-text-field>
        </td>
      </tr>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon size="small" class="me-2" @click="this.affiliateSelected = item;apiDialog = true; ">
        mdi-api
      </v-icon>
      <v-icon size="small" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table-server>
</template>
  
<script>
import { API, graphqlOperation } from 'aws-amplify';
import { listAffiliates } from '../graphql/queries';
import { createAffiliate, deleteAffiliate } from '../graphql/mutations';

const FakeAPI = {
  async fetch({ search }) {
    return new Promise(resolve => {
      let options = {
        limit: 15
      };
      if (search.name) {

        options.filter = {
          code: { contains: search.name }
        }
      }

      API.graphql(graphqlOperation(listAffiliates, options)).then((result) => {
        resolve({ items: result.data.listAffiliates.items, total: result.data.listAffiliates.items.length })
      }).catch(error => {
        console.error('Error retreiving affiliates:', error);
      });

    })
  },
}

export default {
  data() {
    return {
      dialog: false,
      apiDialog: false,
      dialogDelete: false,
      affiliateSelected: true,
      createForm: {
        affiliate: '',
        name: '',
        apiKey: '',
        expireDate: null
      },
      itemsPerPage: 15,
      headers: [
        {
          title: 'Affiliate',
          align: 'start',
          sortable: false,
          key: 'code',
        },
        {
          title: 'Name',
          align: 'start',
          sortable: false,
          key: 'name',
        },
        { title: 'API Key', key: 'apiKey', sortable: false, align: 'end' },
        { title: 'Expires At', key: 'expiresAt', sortable: false, align: 'end' },
        { title: 'Actions', key: 'actions', sortable: false }
      ],
      serverItems: [],
      loading: true,
      totalItems: 0,
      name: '',
      search: '',
    }
  },
  watch: {
    name() {
      this.search = String(Date.now())
    }
  },
  methods: {
    showDialog() {
      this.createForm.apiKey = this.generateApiKey();
    },
    loadItems({ page, itemsPerPage, sortBy }) {
      this.loading = true
      FakeAPI.fetch({ page, itemsPerPage, sortBy, search: { name: this.name } }).then(({ items, total }) => {
        this.serverItems = items
        this.totalItems = total
        this.loading = false
      })
    },
    generateApiKey() {
      let apiKey = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      for (let i = 0; i < 32; i++) {
        apiKey += characters.charAt(Math.floor(Math.random() * characters.length));
      }

      return apiKey;
    },
    createAffiliateSubmit() {
      return API.graphql(graphqlOperation(createAffiliate, {
        input: {
          code: this.createForm.affiliate,
          name: this.createForm.name,
          apiKey: this.createForm.apiKey,
          expiresAt: this.createForm.expireDate == null ? null : new Date(this.createForm.expireDate).toISOString(),
          isActive: true
        }
      }))
        .then(() => {
          this.dialog = false;
          this.search = String(Date.now())
        })
        .catch(error => {
          console.error('Error creating affiliate:', error);
        });
    },
    deleteItem(item) {
      this.editedIndex = this.serverItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.serverItems.splice(this.editedIndex, 1)
      return API.graphql(graphqlOperation(deleteAffiliate, {
        input: {
          code: this.editedItem.code,
        }
      }))
        .then(() => {
          this.closeDelete()
        })
        .catch(error => {
          console.error('Error creating affiliate:', error);
        });
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>