<template>
    <v-navigation-drawer permanent>
        <template v-slot:prepend>
            <v-list-item lines="two" prepend-icon="mdi-account" :title="user.username" subtitle="Logged in"></v-list-item>
        </template>

        <v-divider></v-divider>

        <v-list>
            <router-link to="/"><v-list-item prepend-icon="mdi-home-city" title="Home" value="home"></v-list-item></router-link>
            <router-link to="/affiliates"> <v-list-item prepend-icon="mdi-account-heart" title="Affiliates" value="affiliates"></v-list-item></router-link>
            <router-link to="/coupons"> <v-list-item prepend-icon="mdi-ticket-percent" title="Coupons" value="coupons"></v-list-item></router-link>


        <v-spacer></v-spacer>
        <v-divider></v-divider>

       <v-list-item @click="signOut" prepend-icon="mdi-logout" title="Logout" value="logout"></v-list-item>
    </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    props: ['user', 'signOut']
}
</script>