/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAffiliate = /* GraphQL */ `
  query GetAffiliate($code: String!) {
    getAffiliate(code: $code) {
      code
      name
      apiKey
      isActive
      expiresAt
      coupons {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAffiliates = /* GraphQL */ `
  query ListAffiliates(
    $code: String
    $filter: ModelAffiliateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAffiliates(
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        name
        apiKey
        isActive
        expiresAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      affiliateCODE
      code
      data
      state
      startsAt
      expiresAt
      soldAt
      txId
      claimedAt
      affiliate {
        code
        name
        apiKey
        isActive
        expiresAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      affiliateCouponsCode
      owner
      __typename
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        affiliateCODE
        code
        data
        state
        startsAt
        expiresAt
        soldAt
        txId
        claimedAt
        createdAt
        updatedAt
        affiliateCouponsCode
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
