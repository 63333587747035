/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-south-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apiSisalCoupons",
            "endpoint": "https://onnlr6f527.execute-api.eu-south-1.amazonaws.com/dev",
            "region": "eu-south-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://p5v2skradbbevp6rze75b7hifq.appsync-api.eu-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ilh2td2btna7vexxlyd75gsnbq",
    "aws_cognito_identity_pool_id": "eu-south-1:0091ac3c-d611-439c-b192-5b475a4c6af1",
    "aws_cognito_region": "eu-south-1",
    "aws_user_pools_id": "eu-south-1_TOpTaEatm",
    "aws_user_pools_web_client_id": "a87v2eq920plsrgpl7f2c7cb9",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
