<template>
  <authenticator :hide-sign-up="true">
    <template v-slot="{ user, signOut }">
      <v-layout>
        <nav-bar :user="user" :signOut="signOut"></nav-bar>
        <v-main style="min-height: 300px;margin-left: 15px;margin-right: 15px;">
          <router-view></router-view>
        </v-main>
      </v-layout>
    </template>
  </authenticator>
</template>

<script>
import MainPage from './components/MainPage.vue'
import NavBar from "./components/NavBar.vue";

export default {
  name: 'App',
  components: {
    MainPage,
    NavBar
  }
}
</script>

<script setup>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
